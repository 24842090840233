import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { AnalyticType } from 'store/analytics/types';
import { Button, Select, Group, Checkbox, Text, Modal, Stack } from '@mantine/core';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkDeletePrescriptions,
  bulkExportDownloadPrescriptions,
  bulkExportPrescriptions,
  deletePrescription,
} from 'store/prescriptions/requests';
import { getFieldGeometry } from 'store/fields/thunks';
import { PrescriptionType } from 'store/prescriptions/types';
import { getExportedPrescriptions, getProPrescriptions } from 'store/prescriptions/thunks';
import showToast from 'actions/toastActions';
import { getExportTypes } from 'util/prescription';
import SummaryChart from '../SummaryChart';

import commonStyles from '../Container.module.css';
import prescriptionStyles from './Prescription.module.css';
import PrescriptionMapbook from './PrescriptionMapbook';
import { RootState } from 'store';
import { SHAPEFILE_FORMAT } from 'constants/results';

interface PrescriptionListPropsType {
  analytics: AnalyticType[];
  fieldGeometry: FieldType;
  samplingPlan: SamplingPlanType;
}

const BULK_DELETE = 'delete';
const BULK_EXPORT = 'export';

const PrescriptionList = ({
  analytics,
  fieldGeometry,
  samplingPlan,
}: PrescriptionListPropsType) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [cancelledScripts, setCancelledScripts] = useState<number[]>([]);
  const [bulkSelecting, setBulkSelect] = useState<string | null>(null);
  const [selectedScripts, setSelectedScripts] = useState<number[]>([]);
  const [showModal, toggleShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, operation_id, prescriptions } = fieldGeometry.features[0].properties;

  const { operation, proPrescriptions } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[operation_id],
    proPrescriptions: state.prescriptionZones.proPrescriptions[id] || [],
  }));

  const listOutputFormats = getExportTypes(language, operation.external_connected_accounts);
  const [exportType, setExportType] = useState<string>(SHAPEFILE_FORMAT);

  const navigateToPrescriptions = useCallback(() => {
    navigate(`/results/rx/${operation_id}/${id}/create`);
  }, [fieldGeometry, navigate]);

  const showMessage = useCallback(
    (message: string, type = '', timeout = 5000) => dispatch(showToast(message, type, timeout)),
    [dispatch],
  );

  useEffect(() => {
    dispatch(getExportedPrescriptions(id));
    dispatch(getProPrescriptions(id));
  }, []);

  const cancelPrescription = async (prescription: PrescriptionType) => {
    try {
      if (!cancelledScripts.includes(prescription.id)) {
        setCancelledScripts([...cancelledScripts, prescription.id]);
        await deletePrescription(prescription.id);
        showMessage(
          `${getString('successfullyRemovedPrescription', language)} - ${prescription.name}`,
        );
        await Promise.all([
          dispatch(getFieldGeometry(prescription.field_id)),
          dispatch(getProPrescriptions(prescription.field_id)),
        ]);
      }
    } catch (err) {
      showMessage(getString('errorDeletingPrescription', language), 'error');
    }
  };

  const scripts = prescriptions
    .filter(
      (prescription) => prescription.show_in_ui && !cancelledScripts.includes(prescription.id),
    )
    .concat(proPrescriptions);

  const handleCheckbox = (prescription: PrescriptionType) => {
    setSelectedScripts((currentSelected) => {
      if (currentSelected.includes(prescription.id)) {
        return currentSelected.filter((pid) => pid !== prescription.id);
      }
      return currentSelected.concat([prescription.id]);
    });
  };

  const handleSelectAll = () => {
    setSelectedScripts((currentSelected) => {
      if (prescriptions.every((p) => currentSelected.includes(p.id))) {
        return [];
      }
      return prescriptions.map((p) => p.id);
    });
  };

  const handleBulkDelete = async () => {
    try {
      setLoading(true);
      await bulkDeletePrescriptions(selectedScripts);
      setSelectedScripts([]);
      showMessage(`${getString('successfullyRemovedPrescription', language)}`);
      dispatch(getFieldGeometry(id));
      setBulkSelect(null);
    } catch (err) {
      showMessage(getString('errorDeletingPrescription', language), 'error');
    } finally {
      setLoading(false);
      toggleShowModal(false);
    }
  };

  const handleBulkExport = async () => {
    try {
      setLoading(true);
      const selectedExportType = listOutputFormats.find((f) => f.value === exportType);
      if (selectedExportType?.leaf_user_uuid) {
        await bulkExportPrescriptions(
          selectedScripts,
          exportType,
          selectedExportType?.leaf_user_uuid,
        );
        showMessage(getString('exportInitiatedMsg', language));
      } else {
        await bulkExportDownloadPrescriptions(selectedScripts, exportType);
        showMessage(getString('prescriptionDownloadSuccessMsg', language));
      }
      setSelectedScripts([]);
      setBulkSelect(null);
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
      toggleShowModal(false);
    }
  };

  return (
    <div className={commonStyles.MapbookWrapper}>
      <SummaryChart
        analyticsPerTab={analytics}
        onClickChartBar={() => {}}
        samplingPlan={samplingPlan}
      />
      <div className={commonStyles.MapbookBar}>{getString('prescriptions', language)}</div>
      <div className={prescriptionStyles.MenuBar}>
        <Group>
          {!!scripts.length && (
            <Select
              disabled={bulkSelecting !== null}
              data={[BULK_DELETE, BULK_EXPORT]}
              value={bulkSelecting}
              onChange={(val: string | null) => setBulkSelect(val)}
              placeholder={getString('options', language)}
            />
          )}
          <Button onClick={navigateToPrescriptions}>
            {getString('createNutrientScript', language)}
          </Button>
        </Group>
        {bulkSelecting && (
          <Group>
            <Checkbox
              checked={prescriptions.every((p) => selectedScripts.includes(p.id))}
              onChange={handleSelectAll}
              labelPosition="left"
              label={<Text fw={500}>{getString('selectAll', language)}</Text>}
            />
            <Group className={prescriptionStyles.AdjacentButtons}>
              <Button
                variant="outline"
                onClick={() => {
                  setSelectedScripts([]);
                  setBulkSelect(null);
                }}
              >
                {getString('cancel', language)}
              </Button>
              <Button
                onClick={() => toggleShowModal(true)}
                disabled={!selectedScripts.length}
                color={bulkSelecting === BULK_DELETE ? 'darkRed' : undefined}
              >
                {getString(
                  bulkSelecting === BULK_DELETE ? 'removeSelected' : 'exportSelected',
                  language,
                )}
              </Button>
            </Group>
          </Group>
        )}
      </div>
      {scripts.map((prescription) => (
        <PrescriptionMapbook
          key={prescription.id}
          prescription={prescription}
          fieldGeometry={fieldGeometry}
          cancelPrescription={cancelPrescription}
          checked={bulkSelecting ? selectedScripts.includes(prescription.id) : undefined}
          onChecked={() => handleCheckbox(prescription)}
        />
      ))}
      {showModal && (
        <Modal
          opened={showModal}
          onClose={() => toggleShowModal(false)}
          title={
            bulkSelecting === BULK_DELETE
              ? getString('bulkDeleteRxMsg', language)
              : getString('bulkExportRxMsg', language)
          }
          centered
          size="lg"
        >
          <Stack>
            {bulkSelecting === BULK_EXPORT && (
              <Select
                className={prescriptionStyles.SelectExportType}
                data={listOutputFormats}
                value={exportType}
                onChange={(val) => val && setExportType(val)}
                placeholder={getString('selectFormat', language)}
              />
            )}
            <Group justify="flex-end">
              <Button
                disabled={loading}
                onClick={bulkSelecting === BULK_DELETE ? handleBulkDelete : handleBulkExport}
                color={bulkSelecting === BULK_DELETE ? 'darkRed' : undefined}
              >
                {getString('confirm', language)}
              </Button>
              <Button disabled={loading} variant="outline" onClick={() => toggleShowModal(false)}>
                {getString('cancel', language)}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </div>
  );
};

export default PrescriptionList;
