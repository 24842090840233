import React, { useEffect, useMemo, useState } from 'react';
import { Button, Center, Divider, Flex, Loader, Modal, Stack, Text, Title } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';
import { userFullName } from 'util/userRoles';
import { confirmCollectionAssignment, fetchCollections } from 'store/eoCollections/thunks';
import { EOCollectionFeatureType, EOCollectionFieldFeatureType } from 'store/eoCollections/types';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { RED } from 'util/mapImageryColors';
import setToast from 'actions/toastActions';
import OperationCollections from './OperationCollections';

import styles from './Container.module.css';

export type OperationCollectionsType = {
  fields: EOCollectionFieldFeatureType[];
  name: string;
};

type CollectionsByOperation = {
  [operationId: number]: { fields: EOCollectionFieldFeatureType[]; name: string };
};

const DataInboxContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [selectedFieldIds, setSelectedFieldIds] = useState<number[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [modalVisible, { open, close }] = useDisclosure(false);

  const { currentUser, fieldCollections, hasFetched } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
    fieldCollections: state.eoCollections.fieldCollections,
    hasFetched: state.eoCollections.hasFetched,
  }));

  const groupedCollectionsByOperation = useMemo(
    () =>
      fieldCollections.reduce((result, field) => {
        const { operation } = field.features[0].properties;
        const fields = (result[operation.id]?.fields || []).concat(field);
        return {
          ...result,
          [operation.id]: {
            name: operation.name,
            fields,
          },
        };
      }, {} as CollectionsByOperation),
    [fieldCollections],
  );

  const getCollections = () => dispatch(fetchCollections());

  useEffect(() => {
    getCollections();
  }, []);

  const handleSubmit = async () => {
    try {
      setIsConfirming(true);
      const activeFieldCollections = fieldCollections.filter((field) =>
        selectedFieldIds.includes(field.features[0].properties.id),
      );
      const allCollections = activeFieldCollections.reduce(
        (result, field) => result.concat(field.features[0].properties.pending_eo_collections),
        [] as EOCollectionFeatureType[],
      );
      const assignments = allCollections.reduce(
        (result, collection) => {
          const { id: collectionId, sampling_plan_id } = collection.properties;
          const ids = (result[sampling_plan_id] || []).concat(collectionId);
          return {
            ...result,
            [sampling_plan_id]: ids,
          };
        },
        {} as { [samplingPlanId: number]: number[] },
      );
      const payload = { assignments };
      await dispatch(confirmCollectionAssignment(payload));
      setIsConfirming(false);
      close();
    } catch (error) {
      dispatch(setToast(getString('dataConfirmationErrorMsg', language), 'error'));
    }
  };

  const toggleField = (fieldId: number) => {
    if (selectedFieldIds.includes(fieldId)) {
      setSelectedFieldIds(selectedFieldIds.filter((id) => id !== fieldId));
    } else {
      setSelectedFieldIds(selectedFieldIds.concat(fieldId));
    }
  };

  if (!currentUser || (!hasFetched && !fieldCollections.length)) {
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Title order={1}>
        {getString('scanHistory', language)} - {userFullName(currentUser)}
      </Title>
      <Divider size="sm" />
      {fieldCollections.length ? (
        <>
          {Object.keys(groupedCollectionsByOperation).map((operationId, index) => (
            <OperationCollections
              collections={groupedCollectionsByOperation[operationId]}
              defaultOpen={index === 0}
              key={operationId}
              selectedFieldIds={selectedFieldIds}
              toggleField={toggleField}
            />
          ))}
          <Flex className={styles.Confirm} justify="flex-end">
            <Button disabled={!selectedFieldIds.length} onClick={open}>
              {getString('confirmFieldAssignment', language)}
            </Button>
          </Flex>
        </>
      ) : (
        <Center h="50vh">
          <Title order={3}>{getString('noDataToReview', language)}</Title>
        </Center>
      )}
      {modalVisible && (
        <Modal
          opened={modalVisible}
          onClose={close}
          centered
          withCloseButton={false}
          size="lg"
          ta="center"
        >
          <Stack p="2rem">
            <Title
              order={1}
              fw={FONT_WEIGHT_BOLD}
            >{`${getString('confirmFieldAssignment', language)}?`}</Title>
            <Text>{getString('confirmCollectionMsg1', language)}</Text>
            <Text>{getString('confirmCollectionMsg2', language)}</Text>
            <Flex justify="flex-end">
              <Button bg={RED} onClick={close} variant="filled">
                {getString('cancel', language)}
              </Button>
              <Button disabled={isConfirming} ml="1rem" onClick={handleSubmit}>
                {getString('confirm', language)}
              </Button>
            </Flex>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default DataInboxContainer;
