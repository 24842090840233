import { AMMONIUM_ACETATE, BRAY, DTPA, M3, OLSEN } from './analysis';

export const EGGS = 'eggs';
export const DNA_COPIES = 'dna copies';
export const WORMS = 'worms';

export const CROP_PROTECTION = 'crop-protection';
export const NUTRIENTS = 'nutrients';
export const RX = 'rx';
export const COMPACTION = 'compaction';
export const TOAST_TWELVE = 12000;
export const PDF_FORMAT = 'pdf';
export const CSV_FORMAT = 'csv';
export const SHAPEFILE_FORMAT = 'shapefile';
export const CSV_PRO_FORMAT = 'pro_csv';
export const SHAPEFILE_PRO_FORMAT = 'pro_shapefile';
export const SUMMARY = 'summary';
export const ANALYTIC_DETAILS = 'analytic-details';
export const FIELD_COMPARISON = 'field-comparison';
export const ZONE_DETAILS = 'zone-details';
export const MAP_ANALYTIC = 'map-analytic';

export const NUTRIENT_PANEL_NOT_ANALYZED = 'nutrient_panel_not_analyzed';
export const PERFORMANCE_PANEL_NOT_ANALYZED = 'performance_panel_not_analyzed';
export const PRESSURE_PANEL_NOT_ANALYZED = 'pressure_panel_not_analyzed';

export const FILTER_OPTIONS = [
  {
    id: 1,
    displayName: 'Completed',
    value: 'completed',
  },
  {
    id: 2,
    displayName: 'All',
    value: 'all',
  },
];
export const QUICKBOOKS_TERMS = [
  { displayName: 'Net 15', id: 0 },
  { displayName: 'Net 30', id: 1 },
  { displayName: 'Net 60', id: 2 },
];

export const REFUND_THRESHOLD = 0.25;

export const PATHOGENS = 'pathogens';
export const FOLIAR = 'foliar';
export const BIOFERTILITY = 'bioactive';
export const SOIL_ATTRIBUTES = 'soil attributes';
export const SOIL_HEALTH = 'soil health';

export const BCSR = 'bcsr';

export const CORN_PATHOGENS = 'cornPathogens';
export const CORN_SOYBEAN_PATHOGENS = 'cornAndSoybeanPathogens';
export const SOYBEAN_PATHOGENS = 'soybeanPathogens';
export const COTTON_PATHOGENS = 'cottonPathogens';
export const WHEAT_PATHOGENS = 'wheatPathogens';
export const CORN_FOLIAR = 'cornFoliar';
export const SOYBEAN_FOLIAR = 'soybeanFoliar';
export const COTTON_FOLIAR = 'cottonFoliar';
export const WHEAT_FOLIAR = 'wheatFoliar';
export const SUGAR_BEETS = 'sugar-beets';
export const SUGAR_BEET_PESTS_DISEASE = 'sugarBeetPestsDiseases';
export const BENEFICIAL_BIOMARKERS = 'beneficialBioMarkers';
export const HARMFUL_BIOMARKERS = 'harmfulBiomarkers';
export const MACRONUTRIENTS = 'macronutrients';
export const MICRONUTRIENTS = 'micronutrients';
export const CATION_RATIOS = 'cationRatios';

export const ANALYTIC_DISPLAY_CATEGORIES = [
  CROP_PROTECTION,
  FOLIAR,
  BIOFERTILITY,
  NUTRIENTS,
  COMPACTION,
  BCSR,
  RX,
];

export const RESULT_DISPLAY_CATEGORIES = [CROP_PROTECTION, FOLIAR, BIOFERTILITY, NUTRIENTS, BCSR];

export const categoryDisplayNames = {
  [PATHOGENS as string]: 'Pathogens',
  [FOLIAR as string]: 'Foliar',
  [SUGAR_BEETS as string]: 'Sugar Beets',
  [BIOFERTILITY as string]: 'Biofertility',
  [SOIL_ATTRIBUTES as string]: 'Soil Attributes',
  [SOIL_HEALTH as string]: 'Soil Health',
  [BCSR as string]: 'BCSR',
  [COMPACTION as string]: 'Compaction',
};

export const WESTERN_CORN_ROOTWORM_ID = 1;
export const NORTHERN_CORN_ROOTWORM_ID = 2;
export const ROOTWORM_ID = 3;
export const ANTHRACNOSE_CORN_ID = 31;
export const ANTHRACNOSE_SOYBEAN_ID = 32;
export const GIBBERELLA_STALK_ROOT_ID = 38;
export const SOYBEAN_STEM_CANKER_ID = 41;
export const SOYBEAN_BROWN_STEM_ROT_ID = 40;
export const SUGAR_BEET_FUSARIUM_ID = 42;
export const WHITE_MOLD_ID = 44;
export const SCN_ID = 4;
export const SUDDEN_DEATH_SYNDROME_SHOTGUN_ID = 5;
export const SUDDEN_DEATH_SYNDROME_QPCR_ID = 51;
export const FUSARIUM_ID = 6;
export const PHYTOPHTHORA_ID = 7;
export const PYTHIUM_ID = 8;
export const RHIZOCTONIA_ID = 9;
export const SOYBEAN_NFIXERS_ID = 10;
export const NITROGEN_FIXATION_POTENTIAL_ID = 11;
export const DENITRIFICATION_POTENTIAL_ID = 12;
export const PSOLUBILIZATION_POTENTIAL_ID = 13;
export const PLANT_GROWTH_PROMOTERS_ID = 14;
export const PH_ID = 15;
export const CEC_ID = 16;
export const OM_ID = 17;
export const CALCIUM_ID = 18;
export const DIVERSITY_ID = 19;
export const GRAM_POSITIVE_RATIO_ID = 20;
export const GRAM_NEGATIVE_ID = 21;
export const GRAM_POSITIVE_ID = 22;
export const ANAEROBIC_POTENTIAL_ID = 23;
export const SOYBEAN_NFIXERS2_ID = 24;
export const IRON_ID = 25;
export const BPH_ID = 26;
export const MAGNESIUM_ID = 27;
export const BORON_ID = 28;
export const MANGANESE_ID = 29;
export const APHANOMYCES_ID = 33;
export const CERCOSPORA_BLIGHT_SOYBEAN_ID = 35;
export const CERCOSPORA_LEAF_SPOT_SUGAR_BEET_ID = 36;
export const VERTICILLIUM_WILT_ID = 43;
export const NITROGEN_ID = 45;
export const PHOSPHORUS_ID = 46;
export const POTASSIUM_ID = 47;
export const AMF_ID = 30;
export const FROGEYE_LEAF_SPOT_ID = 37;
export const GRAY_LEAF_SPOT_ID = 39;
export const COPPER_ID = 48;
export const ZINC_ID = 49;
export const SULFUR_ID = 50;
export const PATHOGENIC_ASPERGILLUS_ID = 53;
export const SHARP_EYESPOT_WHEAT_ID = 55;
export const GOSS_WILT_ID = 56;
export const TARGET_SPOT_OF_COTTON_ID = 57;
export const NORTHERN_CORN_LEAF_BLIGHT_ID = 58;
export const SOYBEAN_CYST_NEMATODE_360_ID = 59;
export const SUGAR_BEET_CYST_NEMATODE_ID = 60;
export const TAKE_ALL_DISEASE_WHEAT_ID = 61;
export const CHARCOAL_ROT_ID = 62;
export const TAR_SPOT_ID = 66;
export const TEXAS_ROOT_ROT_OF_COTTON_ID = 67;
export const DIPLODIA_EAR_ROT_ID = 71;
export const BACTERIAL_LEAF_STREAK_WHEAT_ID = 73;
export const BACTERIAL_BLIGHT_OF_COTTON_ID = 74;
export const BACTERIAL_LEAF_STREAK_CORN_ID = 75;
export const RHIZOCTONIA_SUGAR_BEET_ID = 76;
export const ROOT_KNOT_NEMATODE_360_ID = 77;
export const BLACK_POINT_DISEASE_WHEAT_ID = 82;
export const RED_CROWN_ROT_ID = 86;
export const BENEFICIAL_PANTOEA_WHEAT_ID = 89;
export const RUST_ID = 92;
export const SEPTORIA_BROWN_SPOT_ID = 95;
export const DISSIMILATORY_NITRATE_REDUCTION_TO_AMMONIA = 97;
export const ASSIMILATORY_NITRATE_REDUCTION_TO_AMMONIA = 98;
export const NITRIFICATION = 99;
export const SHOTGUN_WESTERN_CORN_ROOTWORM_ID = 100;
export const SHOTGUN_NORTHERN_CORN_ROOTWORM_ID = 101;
export const SHOTGUN_SOUTHERN_CORN_ROOTWORM_ID = 102;
export const RHIZOCTONIA_COTTON_BALL_ID = 103;
export const FUSARIUM_COTTON_BALL_ROT_ID = 104;
export const SHOTGUN_ROOTWORM_ID = 105;
export const CHARCOAL_ROT_COTTON_ID = 106;
export const FUSARIUM_HEAD_BLIGHT_ID = 110;
export const PYTHIUM_WHEAT_ID = 111;
export const RHIZOCTONIA_WHEAT_ID = 112;
export const TAN_SPOT = 113;
export const LOOSE_SMUT_ID = 114;
export const BPH_SIKORA_ID = 1006;
export const P_BRAY_ID = 1007;
export const K_AMMONIUM_ACETATE_ID = 1008;
export const CA_AMMONIUM_ACETATE_ID = 1009;
export const MG_AMMONIUM_ACETATE_ID = 1010;
export const BASE_SATURATION = 1011;
export const CA_SATURATION = 1012;
export const MG_SATURATION = 1013;
export const K_SATURATION = 1014;
export const CA_MG_RATIO = 1015;
export const CA_K_RATIO = 1016;
export const MG_K_RATIO = 1017;
export const SSURGO_OM_ID = 1018;
export const P_OLSEN_ID = 1019;
export const B_DTPA_ID = 1020;
export const FE_DTPA_ID = 1021;
export const MN_DTPA_ID = 1022;
export const CU_DTPA_ID = 1023;
export const ZN_DTPA_ID = 1024;
export const ALUMINUM_ID = 1025;
export const AL_SATURATION = 1026;

export const SOYBEAN_CYST_NEMATODE_RKN = 1027;

export const OM_MEASURED_ID = 2026;
export const COMPACTION_ID = 2029;

export const phosphorusIds = [PHOSPHORUS_ID, P_BRAY_ID, P_OLSEN_ID];
export const bufferPhIds = [BPH_ID, BPH_SIKORA_ID];
export const allPhIds = [...bufferPhIds, PH_ID];
export const potassiumIds = [POTASSIUM_ID, K_AMMONIUM_ACETATE_ID];
export const calciumIds = [CALCIUM_ID, CA_AMMONIUM_ACETATE_ID];
export const magnesiumIds = [MAGNESIUM_ID, MG_AMMONIUM_ACETATE_ID];
export const boronIds = [BORON_ID, B_DTPA_ID];
export const ironIds = [IRON_ID, FE_DTPA_ID];
export const manganeseIds = [MANGANESE_ID, MN_DTPA_ID];
export const copperIds = [COPPER_ID, CU_DTPA_ID];
export const zincIds = [ZINC_ID, ZN_DTPA_ID];
export const altNutrientIds = [
  P_BRAY_ID,
  P_OLSEN_ID,
  K_AMMONIUM_ACETATE_ID,
  CA_AMMONIUM_ACETATE_ID,
  MG_AMMONIUM_ACETATE_ID,
  B_DTPA_ID,
  FE_DTPA_ID,
  MN_DTPA_ID,
  CU_DTPA_ID,
  ZN_DTPA_ID,
];
export const macronutrientIds = [...calciumIds, ...potassiumIds, ...magnesiumIds];
export const micronutrientIds = [
  ...boronIds,
  ...ironIds,
  ...manganeseIds,
  ...copperIds,
  ...zincIds,
  ALUMINUM_ID,
];

export const calibratedAnalyticIds = [
  SUDDEN_DEATH_SYNDROME_QPCR_ID,
  SHOTGUN_WESTERN_CORN_ROOTWORM_ID,
  SHOTGUN_NORTHERN_CORN_ROOTWORM_ID,
  SHOTGUN_ROOTWORM_ID,
  ROOT_KNOT_NEMATODE_360_ID,
  SOYBEAN_CYST_NEMATODE_360_ID,
];

export const qpcrAnalyticIds = [
  ROOTWORM_ID,
  WESTERN_CORN_ROOTWORM_ID,
  NORTHERN_CORN_ROOTWORM_ID,
  SCN_ID,
  SOYBEAN_CYST_NEMATODE_RKN,
  SUDDEN_DEATH_SYNDROME_QPCR_ID,
];

export const analyticToMethodMap = {
  // P
  [PHOSPHORUS_ID]: M3,
  [P_BRAY_ID]: BRAY,
  [P_OLSEN_ID]: OLSEN,
  // K
  [POTASSIUM_ID]: M3,
  [K_AMMONIUM_ACETATE_ID]: AMMONIUM_ACETATE,
  // Ca
  [CALCIUM_ID]: M3,
  [CA_AMMONIUM_ACETATE_ID]: AMMONIUM_ACETATE,
  // Mg
  [MAGNESIUM_ID]: M3,
  [MG_AMMONIUM_ACETATE_ID]: AMMONIUM_ACETATE,
  // B
  [BORON_ID]: M3,
  [B_DTPA_ID]: DTPA,
  // Fe
  [IRON_ID]: M3,
  [FE_DTPA_ID]: DTPA,
  // Mn
  [MANGANESE_ID]: M3,
  [MN_DTPA_ID]: DTPA,
  // Cu
  [COPPER_ID]: M3,
  [CU_DTPA_ID]: DTPA,
  // Zn
  [ZINC_ID]: M3,
  [ZN_DTPA_ID]: DTPA,
};

export const pathogensWithNameDiff = [FOLIAR, SUGAR_BEETS];

export const PLANNING_TABS = [CROP_PROTECTION, NUTRIENTS, BIOFERTILITY];

export const PATTERN_RECOMMENDATION_SET = 1;

export const IS_OVERVIEW_ND = 'is_overview_nd';
export const IS_FIELD_ND = 'is_field_nd';
export const IS_SAMPLE_ND = 'is_sample_nd';

export const DISPLAY_ANALYTICS_TOGGLES = [IS_OVERVIEW_ND, IS_FIELD_ND, IS_SAMPLE_ND];

export const PRO_NUTRIENT_ANALYTIC_IDS = [
  PH_ID,
  CEC_ID,
  ...phosphorusIds,
  ...potassiumIds,
  ...bufferPhIds,
  ...magnesiumIds,
  ...boronIds,
  ...calciumIds,
  ...copperIds,
  ...ironIds,
  ...manganeseIds,
  SULFUR_ID,
  ...zincIds,
  OM_ID,
  NITROGEN_ID,
];

export const DEFAULT_RX_DENSITY = { id: 4, value: '0.5_acre', label: '.5 ac' };

export const PRO_EXPORT_ACRE_DENSITY_OPTIONS = [
  { id: 1, value: '10_m', label: '10 m' },
  { id: 2, value: '60_foot', label: '60 ft' },
  { id: 3, value: '80_foot', label: '80 ft' },
  DEFAULT_RX_DENSITY,
  { id: 5, value: '1_acre', label: '1 ac' },
  { id: 6, value: '2.5_acre', label: '2.5 ac' },
  { id: 7, value: '5_acre', label: '5 ac' },
  { id: 8, value: '10_acre', label: '10 ac' },
];

export const BUFFER_PH_MAP_KEY = 'buffer_ph';

export const mapDisplayTypes = {
  pro: 'pro',
  points: 'points',
  highContrast: 'highContrast',
} as const;
