import React from 'react';
import { Button } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { downloadViaAnchorElem, getDataUriStringFromJson, getJsonFromFetch } from 'util/request';
import { useDispatch } from 'react-redux';
import showToast from 'actions/toastActions';
import { formatToDdMmYyyy } from 'util/date';
import { convertSpacesToUnderscores } from 'util/stringUtils';

type Props = {
  fieldName: string;
  url: string;
};

export const DownloadScriptBtn = ({ url, fieldName }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const downloadScript = async () => {
    try {
      const geojson = await getJsonFromFetch<GeoJSON.FeatureCollection>(url);

      if (geojson) {
        const href = getDataUriStringFromJson(geojson);
        const date = formatToDdMmYyyy(new Date().toISOString());

        const filename = convertSpacesToUnderscores(
          `tillage_rx-${fieldName}-${date}.geojson`,
        ).toLowerCase();

        downloadViaAnchorElem(href, filename);
      }
    } catch (err) {
      const isAnErrorObject = err instanceof Error;

      dispatch(
        showToast(
          isAnErrorObject ? err.message : getString('couldNotDownloadLayer', language),
          'error',
        ),
      );
    }
  };

  return (
    <Button onClick={downloadScript} variant="outlined">
      {getString('downloadScript', language)}
    </Button>
  );
};
