import React from 'react';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Header } from 'common';
import { Button } from '@mantine/core';
import { FieldType } from 'store/fields/types';
import { featureCollection } from '@turf/helpers';
import { getSampleGroupings } from 'util/product';
import { SamplePlanType } from 'store/samplePlans/types';
import { ADDITIONAL, RKN, SAMPLING_ZONES, SPLIT_DENSITY } from 'constants/samplePlanning';
import { postSamplePlan } from 'store/samplePlans/requests';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import showToast from 'actions/toastActions';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getSubmitPointsMsg,
  getScannerOptions,
  setupSamplesToSubmitV2,
  is590DensityValid,
} from 'util/samplePlan';
import { ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import styles from './Header.module.css';
import { ZONE_TYPES } from 'constants/mapbox';
import { ZoneAnalysisStateType } from 'store/zoneAnalysisV2/reducer';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import { NUTRIENT_PANEL } from 'constants/products';

interface HeaderProps {
  field: FieldType;
  drawRef: { current: any | null };
  setSavedState: (arg0: ZoneAnalysisStateType['plan']) => void;
}

const SamplingHeader = ({ field, drawRef, setSavedState }: HeaderProps) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const fieldGeometry = useFieldGeometry();

  const isAdditionalPlan = useLocation().pathname.includes(ADDITIONAL);
  const { acreage, name, id, operation_id } = field.features[0].properties;

  const { timings, operation, analysis } = useSelector((state: RootState) => ({
    timings: state.timings.timings,
    operation: state.operations.operationsById[operation_id],
    analysis: state.zoneAnalysisV2.plan,
  }));

  const scannerOptions = getScannerOptions(operation);
  const { operationUsers } = operation;

  const updatePlanState = (metaKeyValue: ZoneAnalysisKeyType) =>
    dispatch(updateZoneAnalysis(metaKeyValue));

  const isEditing = !!analysis.existingPlan && !isAdditionalPlan;
  const isProOrTillRx = analysis.isProScan || analysis.isTillRx;

  const isSubmitDisabled =
    (!analysis.products.length && !analysis.isTillRx) ||
    analysis.isOrderButtonDisabled ||
    analysis.accordionLocation !== SAMPLING_ZONES ||
    !analysis.zones?.features.length ||
    (isProOrTillRx &&
      !analysis.zones?.features.some((zone) => zone.properties?.zone_type === ZONE_TYPES.POINT)) ||
    (analysis.is590Analysis && !is590DensityValid(analysis, fieldGeometry));

  const title = `${name} (${Math.round(acreage)} ${getString('acres', language)}) - ${
    isEditing ? getString('edit', language) : getString('order', language)
  } ${getString('samplePlan', language)}`;

  const submit = async () => {
    try {
      if (operationUsers) {
        if (!timings[analysis.sampleTimingIndex]) {
          return dispatch(showToast(getString('missingInfoMsg', language), 'error', 7000));
        }
        const showPointMsg = getSubmitPointsMsg(analysis, field);
        if (isProOrTillRx && showPointMsg) {
          return dispatch(showToast(getString(showPointMsg, language), 'error', 4000));
        }
        setSavedState(analysis);
        updatePlanState({ isOrderButtonDisabled: true });
        const geometryCollections = setupSamplesToSubmitV2(
          drawRef.current?.getAll(),
          analysis,
          field,
        );
        const body: Partial<SamplePlanType> = {
          temporary: true, // Let the backend know this is a temporary plan
          assigned_to_id:
            analysis.samplerIndex > 0 ? operationUsers[analysis.samplerIndex].id : null,
          assigned_to_scan_id:
            analysis.scannerIndex > 0 ? scannerOptions[analysis.scannerIndex].id : null,
          name: analysis.name,
          notes: analysis.notes,
          sample_timing: timings[analysis.sampleTimingIndex].value,
          ready_to_sample: analysis.readyToSample,
          field_id: id,
          rnd: analysis.rnd,
          is_pro: analysis.isProScan,
          is_till_rx: analysis.isTillRx,
          // @ts-ignore
          samples: featureCollection(geometryCollections),
          pressure_panel_analyze_rkn: analysis.pressureVersion === RKN,
          pressure_panel_amplicon_qpcr: analysis.pressurePlus,
          agency_id: analysis.agencyId,
          zone_type: analysis.creationOption,
          is_590_analysis: analysis.is590Analysis && analysis.products.includes(NUTRIENT_PANEL),
        };
        // Only assign this if in split density mode
        if (analysis.analysisMode === SPLIT_DENSITY) {
          body.sample_groupings = getSampleGroupings(geometryCollections, analysis);
        }
        // Get price summary
        const response = await postSamplePlan(body);
        updatePlanState({
          tempPlan: body,
          disableMapTools: true,
          isConfirmationVisible: true,
          priceSummary: response.price_summary,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      updatePlanState({ isOrderButtonDisabled: false });
      dispatch(showToast(getString('unableToSavePlanMsg', language), 'error', 7000));
    }
  };

  return (
    <Header title={title} className={styles.HeaderClass}>
      <>
        <Button
          data-test-id="order-sample-plan"
          disabled={isSubmitDisabled}
          onClick={submit}
          className={styles.HeaderButton}
        >
          {getString('orderSamplePlan', language)}
        </Button>
        <Button
          variant="outline"
          onClick={() => navigate(`/orders/${operation_id}`)}
          className={styles.HeaderButton}
        >
          {getString('cancel', language)}
        </Button>
      </>
    </Header>
  );
};

export default SamplingHeader;
